/* You can add global styles to this file, and also import other style files */

// ANGULAR MATERIAL INCLUDE
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* GOOGLE FONTS  */
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900|Raleway:100,200,300,400,500,600,700,800,900&display=swap');

/* BOOTSTRAP */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* FONTAWESOME */
@import '@fortawesome/fontawesome-free/css/all.min.css';

/*===============================================================================
MAIN GLOBAL CSS =================================================================
===============================================================================*/
body, html {
    height: 100%;
    margin: 0px auto 0px auto;
    // scroll-behavior: smooth;
}
body {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
}
a {
    text-decoration: none;
}
mat-toolbar {
    background: transparent !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
}
mat-toolbar-row {
    background: transparent !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
}
.spacer {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.container-fluid {
    margin: 0px 0px 0px 0px;
    padding: 0px 200px 0px 200px;
}
/*===============================================================================
MAT DIALOG CONTAINER ============================================================
===============================================================================*/
.custom-dialog-container mat-dialog-container {
    background: var(--transparent);
    display: block;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    display: flex;
    min-height: inherit;
    max-height: inherit;
}
/*===============================================================================
VARIABLE COLORS =================================================================
===============================================================================*/
:root {
    --thegreenedge: #1ABC9C;
    --blackest: #000000;
    --blacker: #080808;
    --black: #101010;
    --lightblack: #181818;
    --lighterblack: #202020;
    --lightestblack: #282828;
    --darkestgray: #303030;
    --darkergray: #383838;
    --darkgray: #404040;
    --lightdarkgray: #484848;
    --lighterdarkgray: #505050;
    --lightestdarkgray: #585858;
    --grayest: #808080;
    --grayer: #808080;
    --gray: #808080;
    --lightgray: #888888;
    --lightergray: #909090;
    --lightestgray: #989898;
    --dirtiestwhite: #DCDCDC;
    --dirtierwhite: #F0F0F0;
    --dirtywhite: #F8F8F8;
    --white: #FFFFFF;
    --lightcream: #FCF9F2;
    --gold: #E4B77D;
    --pink: #F394A7;
    --red: #E81F26;
    --green: #27AE60;
    --purple: #340066;
    --tintpurple: #2e005b;
}
/*===============================================================================
EXTRA LARGE VIEW ================================================================
===============================================================================*/
@media (max-width: 1400px) {
    .container-fluid {
        margin: 0px 0px 0px 0px;
        padding: 0px 150px 0px 150px; 
    }
}
/*===============================================================================
LARGE VIEW ======================================================================
===============================================================================*/
@media (max-width: 1200px) {
    .container-fluid {
        margin: 0px 0px 0px 0px;
        padding: 0px 100px 0px 100px; 
    }
}
/*===============================================================================
MEDIUM VIEW =====================================================================
===============================================================================*/
@media (max-width: 992px) {
    .container-fluid {
        margin: 0px 0px 0px 0px;
        padding: 0px 25px 0px 25px; 
    }
}
/*===============================================================================
SMALL VIEW ======================================================================
===============================================================================*/
@media (max-width: 768px) {
    .container-fluid {
        margin: 0px 0px 0px 0px;
        padding: 0px 15px 0px 15px; 
    }
}